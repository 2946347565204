<template>
  <div class="customerServiceVer">
    <van-nav-bar
      title="客服验证"
      left-arrow
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
      :fixed="true"
      :border="true"
      :placeholder="true"
      v-show="is_navbar === 'true'"
    />
    <div class="customerServiceVerBody">
      <div class="customerServiceVerCenter">
        <van-field
          v-model="mobile"
          placeholder="请输入需要查询的号码"
          center
          clearable
        />
        <div class="queryButton" @click="goQuery">查询</div>
        <div class="tipsContainer">
          <div class="tipTitle">什么是客服验证</div>
          <van-divider class="tipLine" />
          <div class="tipsText">
            输入客服的联系手机号查询当前是否为官方客服，若无查询结果，请谨慎操作！
          </div>
          <div class="tipsText">
            当前平台暂未开放客服通过个人微信、QQ直接联系用户，以此方式联系您的均为假客服，请谨慎操作！
          </div>
        </div>
      </div>
    </div>
    <SuccessDialog
      ref="SuccessDialog"
      :content="dialogContent"
      :title="dialogTitle"
      :iconType="iconType"
    ></SuccessDialog>
  </div>
</template>

<script>
import { SuccessDialog } from "@/components";
import { getVerifyCustomer } from "@/api/security";
export default {
  name: "CustomerServiceVer",
  data() {
    return {
      mobile: "",
      dialogContent: "",
      dialogTitle: "",
      iconType: "",
      is_navbar: "true",
    };
  },

  components: {
    SuccessDialog,
  },
  mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async requestGetVerifyCustomer(config) {
      try {
        const result = await getVerifyCustomer(config);
        if (result.code === 20000 || result.status_code === 1000) {
          this.dialogContent =
            result.data.verify_pass === 1
              ? "当前号码为买个号官方客服" + result.data.nickname
              : "当前号码非任何买个号官方客服，请谨防受骗。";
          this.iconType = result.data.verify_pass;
          this.dialogTitle =
            result.data.verify_pass === 1 ? "验证成功" : "验证失败";
          this.$refs.SuccessDialog._data.show = true;
        } else {
          this.$toast(result.message);
        }
      } catch (error) {
        this.$toast("请求失败");
      }
    },
    goQuery() {
      if (this.mobile === "") {
        this.$toast("请输入内容进行查询");
        return;
      }
      this.requestGetVerifyCustomer({
        mobile: this.mobile,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.customerServiceVer {
  height: 100vh;
  .customerServiceVerBody {
    display: flex;
    justify-content: center;
    .customerServiceVerCenter {
      width: 335px;
      padding-top: 100px;
      .queryButton {
        width: 100%;
        height: 44px;
        background: #00a8ff 100%;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
        border-radius: 15px;
        margin-top: 20px;
      }
      .tipsContainer {
        padding-top: 100px;
        .tipTitle {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          line-height: 22px;
        }
        .tipLine {
          margin: 12px 0px;
        }
        .tipsText {
          height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #888888;
          line-height: 20px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: black;
}
::v-deep .van-cell {
  background: #f5f5f5;
  border-radius: 15px;
}
::v-deep .van-field__control {
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
</style>
